<template>
  <div class="product">
    <div class="product_title">
      <img src="../assets/picture/tittle_bg.png"
           alt="">
      <div class="title_name">{{goods.name.en_US}}</div>
    </div>
    <div class="product_container">
      <div class="product_thumb"
           v-for="(i,index) in goods.cases"
           :key="index">
        <div class="product_box">
          <div class="thumb_image"
               @mouseenter="chooseIn(i.id)"
               @mouseleave="unChoose(i.id)"
               @click='toDetails(goods.id,i.id)'>
            <img class="image_veins"
                 v-show="ishoverIndex==i.id"
                 src="../assets/picture/10001.png"
                 alt="">
            <img class="image_main"
                 src="https://i.postimg.cc/7Z26Dk23/we-10-64.png"
                 alt="">
            <img class="image_move"
                 v-if='i.prospect'
                 :ref="moveClass+i.id"
                 :src="i.prospect"
                 alt="">
          </div>
          <div class="product_name">{{i.name.en_US}}</div>
          <div class="product_btn"
               @click="toDetails(goods.id,i.id)">
            <svg class="MuiSvgIcon-root coin_SvgIcon__icon"
                 focusable="false"
                 viewBox="0 0 1024 1024"
                 aria-hidden="true"
                 width="1em"
                 height="1em"
                 style="font-size: 14px;">
              <path d="M122.688 317.312c14.592-29.44 32.512-57.088 53.248-82.24l-56-56a506.624 506.624 0 00-69.568 106.432l72.32 31.808zm-43.52 165.376c2.24-40.768 10.24-79.936 23.04-116.8l-72.32-31.936A504.064 504.064 0 00.192 482.688h79.04zm132.672-286.08a428.224 428.224 0 0198.816-70.08l-28.672-73.92A507.2 507.2 0 00156.032 140.8l55.808 55.872zM96.448 629.632a425.728 425.728 0 01-17.024-94.336H.384c2.368 42.624 9.984 83.84 22.272 123.008l73.792-28.672zM513.344 79.616c42.88.768 84.288 7.872 123.264 20.416l31.936-72.576A505.088 505.088 0 00513.28.64v78.912zm360.384 79.872L817.92 215.36a427.968 427.968 0 0172.448 105.664l73.728-28.672a507.008 507.008 0 00-90.368-132.864zm-188.096-40.256a427.392 427.392 0 0194.08 59.904l56-56a506.496 506.496 0 00-118.272-76.16l-31.808 72.32zm-326.72-13.824a424.64 424.64 0 01101.76-23.552V2.624a503.296 503.296 0 00-130.304 29.184l28.544 73.6zM178.048 780.8a427.2 427.2 0 01-63.36-101.76l-73.6 28.608a506.24 506.24 0 0080.96 129.088l56-56zm753.728-245.504a424.96 424.96 0 01-27.904 125.952l72.32 31.808c19.52-49.28 31.552-102.336 34.624-157.76H931.84zm-49.92 173.76a428.416 428.416 0 01-66.176 91.328l55.808 55.872a508.183 508.183 0 0082.752-115.328l-72.384-31.872zm28.48-339.264c12.032 35.712 19.52 73.6 21.76 112.896h78.976a504.256 504.256 0 00-27.136-141.568l-73.6 28.672zm-449.6 561.664A424.32 424.32 0 01341.44 901.12l-31.808 72.256c47.168 19.84 97.92 32.64 151.04 37.312v-79.232zm316.416-95.104a424.214 424.214 0 01-108.736 65.28l28.608 73.6a505.92 505.92 0 00136.128-82.88l-56-56zm-482.944 41.536a428.416 428.416 0 01-80-58.88L158.4 874.816a507.52 507.52 0 00103.808 75.648l32-72.576zm324.352 40.768a427.072 427.072 0 01-105.216 15.04v78.912a505.792 505.792 0 00133.952-20.032l-28.736-73.92z"></path>
              <path d="M505.6 110.528a396.16 396.16 0 100 792.32 396.16 396.16 0 000-792.32zM768 612.992c0 15.68-5.056 32.448-15.104 50.304-10.112 17.856-25.472 34.816-46.144 50.944-20.672 16.192-46.72 29.184-78.144 39.04A354.624 354.624 0 01522.368 768c-83.648 0-148.928-22.656-195.904-68.032S256 593.792 256 517.568c0-51.712 10.752-97.344 32.256-137.024a222.72 222.72 0 0193.184-92.032C422.208 266.88 470.848 256 527.424 256c35.008
0 67.2 4.736 96.448 14.272 29.248 9.6 54.08 21.888 74.432 36.928 20.352 15.04 35.84 31.104 46.656 48.192 10.688 17.088 16.064 32.96 16.064 47.744a48.832 48.832 0 01-18.048 38.272 64.768 64.768 0 01-43.968 15.68 46.592 46.592 0 01-28.032-8.064 131.776 131.776 0 01-25.024-26.048c-16.512-23.36-33.792-40.896-51.84-52.608-18.048-11.648-40.96-17.472-68.736-17.472-40 0-72.256 14.528-96.704 43.52-24.512 29.12-36.736 68.864-36.736
119.296 0 23.68 3.2 45.44 9.408 65.28 6.336 19.84 15.36 36.736 27.328 50.688 11.904 14.016 26.24 24.64 43.136 31.872 16.896 7.168 35.392 10.816 55.552 10.816 27.136 0 50.368-5.824 69.76-17.536 19.328-11.712 36.48-29.504 51.328-53.504 8.256-14.144 17.216-25.216 26.752-33.216a53.12 53.12 0 0135.2-11.968c16.64 0 30.336 5.824 41.216 17.536C762.496 587.328 768 599.808 768 612.992z"></path>
            </svg>
            ${{i.one_price}}
            <div class="product_min_btn">OPEN</div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    goods: [],
  },
  data () {
    return {
      ishoverIndex: '',
      moveClass: 'move',
    }
  },
  created () {

  },
  methods: {
    chooseIn (e) {
      this.ishoverIndex = e
      this.$refs[`move${e}`][0].setAttribute("class", "image_move move_up")

    },
    unChoose (e) {
      this.ishoverIndex = ''
      this.$refs[`move${e}`][0].setAttribute("class", "image_move ")
    },
    toDetails (appid, id) {
      console.log('id', appid, id)

      this.$router.push('/details?appid=' + appid + '&id=' + id)
    }
  }
}
</script>

<style lang='scss' scoped>
.product {
  width: 100%;
  margin-top: 20px;
  .product_title {
    color: #c3c3e2;
    width: 62%;
    margin: 36px auto 6px;
    font-size: 20px;
    font-weight: 600;
    margin: 36px auto 6px;
    font-size: 20px;
    > img {
      width: 100%;
    }
  }
  .product_container {
    width: 100%;
    display: flex;
    // justify-content: center;
    // align-items: center;
    flex-wrap: wrap;
    .product_thumb {
      // max-width: 20%;
      .product_box {
        padding: 20px 10px;
        max-width: 280px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &:hover {
          .product_btn {
            // width: 110px;
            padding-right: 0px;
          }

          .product_min_btn {
            display: block;
          }
        }
      }
      .thumb_image {
        position: relative;
        width: 100%;
        height: 250px;

        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1 0 auto;

        .image_veins {
          position: absolute;
          top: 5px;
          left: -10px;
          // bottom: -10px;
          // right: -10px;
          width: 110%;
          z-index: 1;
        }
        .image_main {
          position: absolute;
          top: 20px;
          left: 0px;
          bottom: 0;
          right: 0;
          z-index: 2;
          // width: 167px;
          max-width: 85%;
          margin: 0 auto;
          vertical-align: middle;
        }
        .image_move {
          position: absolute;
          top: 20%;
          left: 0rem;
          bottom: 0;
          right: 0;
          //  width:100%;
          //  height:100%;
          width: 50%;
          margin: 0 auto;
          z-index: 999;
          vertical-align: middle;
        }
        .move_up {
          animation: move_up 2s infinite;
          animation-direction: normal; /*轮流反向播放动画。*/
          animation-timing-function: ease; /*动画的速度曲线*/
          animation-iteration-count: infinite;
          animation-fill-mode: forwards;
        }
        @keyframes move_up {
          0% {
            top: 20%;
            // transform: rotate(0);
          }
          50% {
            top: 10px;
            // transform: rotate(-10deg);
          }
          100% {
            top: 20%;
          }
        }
        .move_down {
          animation: move_down 0.5s infinite;
          animation-direction: normal; /*轮流反向播放动画。*/
          animation-timing-function: ease; /*动画的速度曲线*/
          animation-iteration-count: 1;
          animation-fill-mode: forwards;
        }
        @keyframes move_down {
          0% {
            top: 20px;
            // transform: rotate(-10deg);
          }
          100% {
            top: 30%;
            // transform: rotate(0);
          }
        }
      }
      .product_name {
        font-size: 14px;
        color: #fff;
        margin-top: -10px;
      }
      .product_btn {
        // width: 80px;
        height: 30px;
        // background: #d30400;
        color: #e9b101;
        font-size: 14px;
        border-radius: 30px;
        margin-top: 15px;
        line-height: 30px;
        font-weight: bold;
        cursor: pointer;
        text-align: left;
        border: 1px solid #e9b101;
        position: relative;
        padding: 0 12px;
        display: flex;
        box-sizing: border-box;
      }
      .MuiSvgIcon-root {
        fill: currentColor;
        width: 1em;
        height: 1em;
        display: inline-block;
        font-size: 1.5rem;
        transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        flex-shrink: 0;
        user-select: none;
        vertical-align: middle;
        margin-top: 7px;
      }
      .product_min_btn {
        display: none;
        color: black;
        height: 30px;
        // margin-right: -2px;
        padding-left: 16px;
        border-radius: 50px;
        padding-right: 16px;
        margin-left: 10px;
        margin-top: -1px;
        background-color: #e9b10e;
      }
    }
  }
}
</style>
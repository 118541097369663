<template>
  <div class="index">
    <!-- <h5 class="title"
        style='text-transform: uppercase;'>{{name}}</h5> -->
    <product :goods="item"
             v-for='(item,index) in goods'
             :key='index'></product>
  </div>

</template>

<script>
import product from '../components/productList.vue'
import goods from '../utils/product'
export default {
  components: {
    product
  },
  data () {
    return {
      goods: [],
      showGoods: [],
      name: ''
    }
  },
  created () {
    this.goods = goods.data
    this.name = this.$store.state.page
    this.getGoods()
  },
  methods: {
    getGoods () {
      // let name = this.name
      // let goods = this.goods
      let showGoods = []

      // goods.map(v => {

      // })
      this.showGoods = showGoods
    }
  },
  watch: {
    "$store.state.page": {
      handler: function (newVal) {
        this.name = newVal
        this.getGoods()
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.index {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // background: url(../assets/picture/bg.jpg) no-repeat ;
}
</style>